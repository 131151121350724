import { PageLayout } from "@components";
import FeatureCard, {
	FeatureCardLayout,
	FeatureCardProps,
} from "@components/FeatureCard/FeatureCard";
import Margins from "@components/Margins/Margins";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import LabProtocolHero from "./_components/LabProtocolHero";
import * as styles from "./protocol.module.scss";
import { JoinInstitutionsV2 } from "pages/landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";
import { RoiV2 } from "@components/RoiV2/RoiV2";
import { FaqProps } from "../reports";
import { FaqV2 } from "@components/FAQV2/FaqV2";
import LabConsumablesCards from "pages/products/lab-consumables/_components/LabConsumablesCards";

import { CardWithIconProps } from "@components/CardWithIcon/CardWithIcon";

// import LabConsumables from '../lab-consumables/index';

const labConsumablesCards: CardWithIconProps[] = [
	{
		icon: (
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clip-path="url(#clip0_713_2707)">
					<path
						d="M19 2.99976H9C7.89543 2.99976 7 3.89519 7 4.99976V14.9998C7 16.1043 7.89543 16.9998 9 16.9998H19C20.1046 16.9998 21 16.1043 21 14.9998V4.99976C21 3.89519 20.1046 2.99976 19 2.99976Z"
						stroke="#2C52F7"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M17 16.9998V18.9998C17 19.5302 16.7893 20.0389 16.4142 20.414C16.0391 20.789 15.5304 20.9998 15 20.9998H5C4.46957 20.9998 3.96086 20.789 3.58579 20.414C3.21071 20.0389 3 19.5302 3 18.9998V8.99976C3 8.46932 3.21071 7.96061 3.58579 7.58554C3.96086 7.21047 4.46957 6.99976 5 6.99976H7"
						stroke="#2C52F7"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</g>
				<defs>
					<clipPath id="clip0_713_2707">
						<rect
							width="24"
							height="24"
							fill="white"
							transform="translate(0 -0.000244141)"
						/>
					</clipPath>
				</defs>
			</svg>
		),
		title: "A source of truth for your inventory",
		description:
			"From reagents to custom item types - we customize to match exactly how it is in the lab.",
		to: "INVENTORY",
		Cta: "Virtual freezers",
	},
	{
		icon: (
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clip-path="url(#clip0_713_3509)">
					<path
						d="M12 9.99976C12 8.40846 11.3679 6.88233 10.2426 5.75712C9.11742 4.6319 7.5913 3.99976 6 3.99976H3V5.99976C3 7.59105 3.63214 9.11718 4.75736 10.2424C5.88258 11.3676 7.4087 11.9998 9 11.9998H12"
						stroke="#2C52F7"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M12 13.9998C12 12.4085 12.6321 10.8823 13.7574 9.75712C14.8826 8.6319 16.4087 7.99976 18 7.99976H21V8.99976C21 10.5911 20.3679 12.1172 19.2426 13.2424C18.1174 14.3676 16.5913 14.9998 15 14.9998H12"
						stroke="#2C52F7"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M12 19.9998V9.99976"
						stroke="#2C52F7"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</g>
				<defs>
					<clipPath id="clip0_713_3509">
						<rect
							width="24"
							height="24"
							fill="white"
							transform="translate(0 -0.000244141)"
						/>
					</clipPath>
				</defs>
			</svg>
		),
		title: "Finally, an ELN built for scientists",
		description:
			"It’s never been easier to cycle across all your experiments, protocols, and attachments in one place.",
		to: "NOTEBOOK",
		Cta: "Explore ELN",
	},
	{
		icon: (
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M12 1.99976C12.5917 1.99976 13.1713 2.05114 13.7348 2.14969M12 1.99976C6.47715 1.99976 2 6.47691 2 11.9998C2 17.5226 6.47715 21.9998 12 21.9998M12 1.99976V21.9998M17.738 3.80876C18.6922 4.47845 19.5241 5.31065 20.1934 6.26516M21.8501 10.2654C21.9486 10.8287 22 11.4082 22 11.9998C22 12.5913 21.9486 13.1708 21.8501 13.7341M20.1892 17.7404C19.5203 18.6927 18.6896 19.5231 17.7369 20.1915M13.7328 21.8502C13.17 21.9485 12.591 21.9998 12 21.9998"
					stroke="#2C52F7"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		),
		title: "Transparent and scalable pricing",
		description:
			"Finally, simple and predictable pricing that scales with your team.",
		to: "PRICING",
		Cta: "View Pricing",
	},
];

const cards: FeatureCardProps[] = [
	{
		title: "An all-in-one protocol hub",
		image: (
			<StaticImage
				src={"./_assets/protocols_image_1.png"}
				alt="Easily Manage and Submit Orders"
			/>
		),
		list: [
			"Create a central hub for all team protocols, making it clear who’s behind them and how they’re used.",
			"Put your recently-used protocols right at the top to speed up your workflow.",
			"See how protocols are being used so that you understand which ones are the most helpful and relevant.",
		],
	},
	{
		title: "Efficient protocol preview and editing",
		image: (
			<StaticImage
				src={"./_assets/protocols_image_2.png"}
				alt="Collaborate for In-depth Order Tracking"
			/>
		),
		list: [
			"Easily preview any protocol so you can decide whether to edit or return.",
			"Navigate seamlessly back and forth between editing a protocol version and the main library .",
			"Minimize any mistakes by making sure you’re always in the right version.",
		],
		orientation: "right",
	},
	{
		title: "Smart protocol tagging and filtering ",
		image: (
			<StaticImage
				src={"./_assets/protocols_image_3.png"}
				alt="Find Orders Quickly and Customize Workflows"
			/>
		),
		list: [
			"Keep things organized by giving each protocol a descriptive label or tag.",
			"Find what you need instantly via targeted searches based those labels.",
			"Simplify your research process by filtering protocols with the tags that matter the most.",
		],
	},
];

const faqItems: FaqProps[] = [
	{
		question: "What is an Electronic Lab Notebook (ELN)?",
		answer: "An Electronic Lab Notebook (ELN) is a modern digital tool designed to replace the traditional paper lab notebooks or basic spreadsheets that many scientists are used to. It’s widely adopted by researchers in various fields, especially in the life sciences. ELNs offer a range of helpful benefits, and when paired with Laboratory Information Management Systems (LIMS), these advantages become even more powerful.",
	},

	{
		question: "What makes Genemod’s protocol hub unique?",
		answer: "Genemod’s central protocol hub streamlines protocol management by offering easy tracking, accessibility, and organization. Scientists can effortlessly preview protocols and monitor their usage. Additionally, our protocol feature includes version control, allowing users to create multiple versions to ensure data integrity and compare documents across different versions.",
	},
	{
		question: "Can I import protocols into projects?",
		answer: "Yes, you can create new documents by importing protocol templates from the library. This allows you to avoid starting an experiment from scratch or repeatedly referencing the protocol library.",
	},
	{
		question: "How can I manage protocols most efficiently?",
		answer: "You can manage protocols efficiently by using customized tags, filters, and version control. Additionally, you can share each protocol with other users, and each protocol version has a unique link, ensuring a single, trusted source of information.",
	},
];

function LabProtocols() {
	return (
		<PageLayout
			seoOptions={{ type: "PREDEFINED", pageName: "lab-protocol" }}
		>
			<Margins className={styles.roibg}>
				<LabProtocolHero />
				<FeatureCardLayout className={styles.cards}>
					{cards.map((card, index) => {
						return (
							<FeatureCard
								font
								sectionAlignment
								{...card}
								key={index}
							/>
						);
					})}
				</FeatureCardLayout>
				<RoiV2 />
				<div className={styles.integrated_bg}>
					<JoinInstitutionsV2
						outerContainerClassName={styles.joinInstitutions}
					/>
				</div>
				<LabConsumablesCards cards={labConsumablesCards} />
				<div className={styles.faqbg}>
					<FaqV2 faqItems={faqItems} />
				</div>
			</Margins>
		</PageLayout>
	);
}

export default LabProtocols;
